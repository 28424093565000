import React from "react";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

import FamilyTree from "./FamilyTree";
import EditFamily from "./EditFamily";
import SearchFamily from "./SearchFamily";

import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

console.log(performance.now() + " Loading index.js");

const router = createBrowserRouter([
  {
    path: "/",
    element: <FamilyTree />,
  },
  {
    path: "/Edit",
    element: <EditFamily />,
  },
  {
    path: "/Search",
    element: <SearchFamily />,
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
