import Spinner from "react-bootstrap/Spinner";

import Swal from "sweetalert2";
import { nanoid } from "nanoid";

const apiDomain = "https://api.onetree.in";
const today = new Date();

export const getInitialNodeFromStorage = () => {
  console.log("getInitialNodeFromStorage");
  const initialNode = localStorage.getItem("initialNode");
  if (initialNode && initialNode.length === 6) return initialNode;
  else return "";
};

export const setInitialNodeInStorage = (value) => {
  console.log(`setInitialNodeInStorage called with value = ${value}`);
  localStorage.setItem("initialNode", value);
};

export const translateNumbers = (input, target) => {
  var systems = {
      devanagari: 2406,
      tamil: 3046,
      kannada: 3302,
      telugu: 3174,
      marathi: 2406,
      malayalam: 3430,
      oriya: 2918,
      gurmukhi: 2662,
      nagari: 2534,
      gujarati: 2790,
    },
    zero = 48, // char code for Arabic zero
    nine = 57, // char code for Arabic nine
    offset = (systems[target.toLowerCase()] || zero) - zero,
    output = input.toString().split(""),
    i,
    l = output.length,
    cc;

  for (i = 0; i < l; i++) {
    cc = output[i].charCodeAt(0);
    if (cc >= zero && cc <= nine) {
      output[i] = String.fromCharCode(cc + offset);
    }
  }
  return output.join("");
};

const nodeCache = {};
const childNodesCache = {};

export const getNode = async (id) => {
  try {
    if (!nodeCache[id]) {
      const response = await fetch(
        `${apiDomain}/node/${id}?initialNode=${localStorage.getItem(
          "initialNode"
        )}`
      );
      nodeCache[id] = await response.json();
    }
    return nodeCache[id];
  } catch (error) {
    console.log("error", error);
  }
};

export const getFamilyNodes = async (id) => {
  try {
    const response = await fetch(
      `${apiDomain}/family/${id}?initialNode=${localStorage.getItem(
        "initialNode"
      )}`
    );
    return await response.json();
  } catch (error) {
    console.log("error", error);
  }
};

export const searchNodes = async ({ f, l, npv }) => {
  try {
    const response = await fetch(
      `${apiDomain}/node/search?f=${f ? f.trim() : ""}&l=${
        l ? l.trim() : ""
      }&npv=${npv ? npv.trim() : ""}`
    );
    const result = await response.json();
    result.Items.forEach((node) => {
      if (!nodeCache[node.id]) nodeCache[node.id] = node;
    });
    return result.Items;
  } catch (error) {
    console.log("error", error);
  }
};

export const updateNode = async (node) => {
  try {
    let id = node.id;
    if (
      !localStorage.getItem("user_pwd") ||
      localStorage.getItem("user_pwd") === ""
    ) {
      localStorage.setItem("user_pwd", nanoid());
    }

    if (
      !localStorage.getItem("initialNode") ||
      localStorage.getItem("initialNode") === ""
    ) {
      const result = await Swal.fire({
        title: "Please Sign In",
        input: "text",
        confirmButtonText: "Sign in",
        preConfirm: (value) => {
          if (!value || value.length !== 6) {
            Swal.showValidationMessage("ID should be six digit");
          } else return value;
        },
      });
      if (result.isConfirmed) localStorage.setItem("initialNode", result.value);
    }

    Object.keys(node).forEach((key) => {
      if (typeof node[key] == "string") node[key] = node[key].trim();
    });

    const response = await fetch(apiDomain + "/node", {
      method: "POST",
      body: JSON.stringify(node),
      headers: {
        authorization:
          localStorage.getItem("initialNode") +
          ":" +
          localStorage.getItem("user_pwd"),
      },
    });
    // Update lineage
    fetch(`${apiDomain}/lineage/${id}`);
    // await fetch(`${apiDomain}/family/${id}?clearCache=true`);

    if (response.status === 200) {
      const data = await response.json();
      id = data.id;
      await Swal.fire({
        text: "Updated successfully " + id,
        icon: "success",
      });
      if (nodeCache[id]) delete nodeCache[id];
      if (childNodesCache[data.p1p]) delete childNodesCache[data.p1p];
      if (childNodesCache[data.p2p]) delete childNodesCache[data.p2p];
    } else {
      await Swal.fire({
        title: "આભાર",
        text: "તમારું અપડેટ પ્રોસેસ થઇ રહ્યું છે",
        icon: "success",
      });
    }
    return id;
  } catch (error) {
    console.log("error", error);
  }
};

export const getParents = async (mainNode) => {
  const parents = [];
  if (mainNode.p1p) {
    const p1pNode = await getNode(mainNode.p1p);
    parents.push(p1pNode);
  }
  if (mainNode.p2p) {
    const p2pNode = await getNode(mainNode.p2p);
    parents.push(p2pNode);
  }
  return parents;
};

export const getChildNodes = async (id) => {
  const childNodes = [];
  try {
    if (!childNodesCache[id]) {
      const response = await fetch(`${apiDomain}/node/${id}/child`);
      childNodesCache[id] = await response.json();
    }
    for (let i = 0; i < childNodesCache[id].length; i++) {
      const childId = childNodesCache[id][i];
      childNodes.push(await getNode(childId));
    }
    return childNodes;
  } catch (error) {
    console.log("error", error);
  }
};

const appendAge = (name, by, dy, n) => {
  let age;
  if (by && dy) {
    age = dy - by;
    name +=
      " (" +
      translateNumbers(by, "gujarati") +
      "-" +
      translateNumbers(dy, "gujarati") +
      ", ઉ. " +
      translateNumbers(age, "gujarati") +
      ")";
  } else if (by && !dy) {
    age = today.getFullYear() - by;
    if (age < 100) name += " (ઉ. " + translateNumbers(age, "gujarati") + ")";
    else name += " (" + translateNumbers(by, "gujarati") + ")";
  } else if (!by && dy) {
    name += " (?-" + translateNumbers(dy, "gujarati") + ")";
  } else if (!by && !dy && n) {
    name += " (" + translateNumbers(n, "gujarati") + ")";
  }
  return name;
};

export const getNodeText = (node) => {
  let name = "";

  if (node.p1f) name += node.p1f;
  if (node.p1l)
    if (name === "") name += node.p1l;
    else name += " " + node.p1l;

  name = appendAge(name, node.p1by, node.p1dy, node.p1n);

  if (node.p2f)
    if (name === "") name += node.p2f;
    else name += "\n" + node.p2f;
  if (node.p2l)
    if (name === "") name += node.p2l;
    else name += " " + node.p2l;

  name = appendAge(name, node.p2by, node.p2dy, node.p2n);

  if (node.r) name += "\n\u2500 (" + node.r + ") \u2500";
  else if (node.g || node.g === 0)
    name +=
      "\n\u2500\u2500 (પેઢી " +
      translateNumbers(node.g, "gujarati") +
      ") \u2500\u2500";
  else name += "\n\u2500\u2500\u2500\u2500\u2500\u2500\u2500";

  if (node.npv)
    if (name === "") name += node.npv;
    else name += "\n" + node.npv;

  if (node.c)
    if (name === "") name += node.c;
    else name += "\n" + node.c;

  return name;
};

export const getNodeTextForSearch = (node) => {
  let name = "";

  if (node.p1f) name += node.p1f;
  if (node.p1l)
    if (name === "") name += node.p1l;
    else name += " " + node.p1l;

  name = appendAge(name, node.p1by, node.p1dy, node.p1n);
  if (node.p1plineage) name += " (" + node.p1plineage.substring(0, 100) + ")";

  if (node.p2f)
    if (name === "") name += node.p2f;
    else name += "\n" + node.p2f;
  if (node.p2l)
    if (name === "") name += node.p2l;
    else name += " " + node.p2l;

  name = appendAge(name, node.p2by, node.p2dy, node.p2n);
  if (node.p2plineage) name += " (" + node.p2plineage.substring(0, 100) + ")";

  if (node.npv)
    if (name === "") name += node.npv;
    else name += "\n" + node.npv;

  if (node.c)
    if (name === "") name += node.c;
    else name += "\n" + node.c;

  return name;
};

export const fireOpenFamilySwal = async (node) => {
  const { id, p1f, p1l, p1plineage, p2f, p2l, p2plineage, dp } = node;
  let footer = "";
  if (p1plineage)
    footer +=
      (p1f || p1l) + " વંશાવલી: " + p1plineage.substring(0, 75) + "<br />";
  if (p2plineage)
    footer += (p2f || p2l) + " વંશાવલી: " + p2plineage.substring(0, 75);

  let titleText = "";
  if (node.p1f) titleText += node.p1f;
  if (node.p2f)
    if (titleText) titleText += " અને " + node.p2f;
    else titleText += node.p2f;
  if (node.p1l) titleText += " " + node.p1l;
  else if (node.p2l) titleText += " " + node.p2l;

  const result = await Swal.fire({
    titleText,
    footer,
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: "ઓપન",
    denyButtonText: "એડિટ",
    cancelButtonText: "સર્ચ",
    showCloseButton: true,
    imageUrl: dp,
  });
  return result;
};

// Spinner JSX
export const MySpinner = (
  <center>
    <Spinner animation="grow" variant="primary" />
    <Spinner animation="grow" variant="secondary" />
    <Spinner animation="grow" variant="success" />
    <Spinner animation="grow" variant="danger" />
    <Spinner animation="grow" variant="warning" />
    <Spinner animation="grow" variant="info" />
    <Spinner animation="grow" variant="light" />
    <Spinner animation="grow" variant="dark" />
  </center>
);
