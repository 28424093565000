import "./index.css";
import React from "react";
import Compress from "compress.js";
import { useSearchParams, useNavigate } from "react-router-dom";
import { getNode, getParents, getChildNodes, updateNode } from "../helpers";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";

const { useState, useEffect } = React;

const EditFamily = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [currentNode, setCurrentNode] = useState(
    searchParams.get("id") || searchParams.get("node")
  );
  const [id, setId] = useState("");
  const [p1n, setP1n] = useState("");
  const [p1f, setP1f] = useState("");
  const [p1l, setP1l] = useState("");
  const [p1by, setP1by] = useState("");
  const [p1dy, setP1dy] = useState("");
  const [p1p, setP1p] = useState("");
  const [p1plineage, setP1plineage] = useState("");

  const [p2n, setP2n] = useState("");
  const [p2f, setP2f] = useState("");
  const [p2l, setP2l] = useState("");
  const [p2by, setP2by] = useState("");
  const [p2dy, setP2dy] = useState("");
  const [p2p, setP2p] = useState("");
  const [p2plineage, setP2plineage] = useState("");

  const [npv, setNpv] = useState("");
  const [dp, setDp] = useState("");

  const [childFamilies, setChildFamilies] = useState([]);

  useEffect(() => {
    console.log("useEffect triggered for currentNode = " + currentNode);
    setId(searchParams.get("id") || searchParams.get("node"));
    setP1n(searchParams.get("p1n"));
    setP1f(searchParams.get("p1f"));
    setP1l(searchParams.get("p1l"));
    setP1by(searchParams.get("p1by"));
    setP1dy(searchParams.get("p1dy"));
    setP1p(searchParams.get("p1p"));
    setP2n(searchParams.get("p2n"));
    setP2f(searchParams.get("p2f"));
    setP2l(searchParams.get("p2l"));
    setP2by(searchParams.get("p2by"));
    setP2dy(searchParams.get("p2dy"));
    setP2p(searchParams.get("p2p"));
    setNpv(searchParams.get("npv"));
    setDp(searchParams.get("dp"));
    setP1plineage(searchParams.get("p1plineage"));
    setP2plineage(searchParams.get("p2plineage"));
    setChildFamilies([]);

    const loadData = async (mainId) => {
      // Main Node
      const mainNode = await getNode(mainId);
      setId(mainNode.id);
      setP1n(mainNode.p1n);
      setP1f(mainNode.p1f);
      setP1l(mainNode.p1l);
      setP1p(mainNode.p1p);
      setP1by(mainNode.p1by);
      setP1dy(mainNode.p1dy);
      setP1plineage(mainNode.p1plineage);

      setP2n(mainNode.p2n);
      setP2f(mainNode.p2f);
      setP2l(mainNode.p2l);
      setP2p(mainNode.p2p);
      setP2by(mainNode.p2by);
      setP2dy(mainNode.p2dy);
      setP2plineage(mainNode.p2plineage);
      setNpv((mainNode.npv || "").replaceAll(",", "").trim());
      setDp(mainNode.dp || "");

      // Parents
      const parentNodes = await getParents(mainNode);
      parentNodes.forEach((parent) => {});

      for (let i = 0; i < parentNodes.length; i++) {
        const parent = parentNodes[i];
        // Grand parents
        const grandParentNodes = await getParents(parent);
        grandParentNodes.forEach((grandParent) => {});

        // Siblings / Cousins
        const siblingNodes = await getChildNodes(parent.id);
        siblingNodes.forEach((sibling) => {});
      }

      // Childrent
      const childNodes = await getChildNodes(mainId);
      setChildFamilies(childNodes);
      childNodes.forEach((childNode) => {});

      // Grand Children
      for (let i = 0; i < childNodes.length; i++) {
        const childNode = childNodes[i];
        const grandChildNodes = await getChildNodes(childNode.id);
        grandChildNodes.forEach((grandChildNodes) => {});
      }
    };

    if (currentNode && currentNode.length === 6) {
      loadData(currentNode);
    }
  }, [currentNode, searchParams]);

  async function updateFamily() {
    const family = {
      id,
      p1n,
      p1f,
      p1l,
      p1by,
      p1dy,
      p2n,
      p2f,
      p2l,
      p2by,
      p2dy,
      npv,
      dp,
      p1p,
      p2p,
      p1plineage,
      p2plineage,
    };
    return await updateNode(family);
  }

  const handleUpdate = (e) => {
    if (e) e.preventDefault();

    if (!p1f && !p1l && !p2f && !p2l) {
      return Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Atleast one name is required",
        showConfirmButton: true,
      });
    }

    Swal.fire({
      title: "Confirm update family",
      showCancelButton: true,
      confirmButtonText: "Update",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const id = await updateFamily();
        navigate("?id=" + id);
        setCurrentNode(id);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };
  return (
    <Form onSubmit={handleUpdate}>
      <Row>
        <Col>&zwnj;</Col>
      </Row>
      <Row>
        <Col>
          <h5>Editing Family: {id}</h5>
        </Col>
        <Col>
          <div className="d-grid gap-2">
            <Button
              className="btn-success"
              onClick={async (e) => {
                const result = await Swal.fire({
                  title: "નવી ફેમિલી ઉમેરો?",
                  confirmButtonText: "હા/Yes",
                  showDenyButton: true,
                });
                if (!result.isDenied) {
                  navigate(`/Edit?id=`);
                  setCurrentNode("");
                }
              }}
            >
              નવી ફેમિલી ઉમેરો
            </Button>
          </div>
        </Col>
        <Col>
          <div className="d-grid gap-2">
            <Button
              className="btn-success"
              onClick={async (e) => {
                const result = await Swal.fire({
                  title: "સર્ચ પેજ પર જાઓ?",
                  confirmButtonText: "હા / Yes",
                  showDenyButton: true,
                });
                if (!result.isDenied) {
                  navigate(`/Search`);
                }
              }}
            >
              સર્ચ ફેમિલી
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>&zwnj;</Col>
      </Row>
      <Accordion defaultActiveKey={["0", "1", "2", "3"]} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>પુરૂષ</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="નામ (અટક બાજુમાં આવસે)"
                >
                  <Form.Control
                    size="lg"
                    type="text"
                    value={p1f || ""}
                    onChange={(e) => {
                      console.log({ e });
                      setP1f(e.target.value);
                    }}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingInput" label="સરનેમ અટક">
                  <Form.Control
                    size="lg"
                    type="text"
                    value={p1l || ""}
                    onChange={(e) => {
                      console.log({ e });
                      setP1l(e.target.value);
                    }}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>&zwnj;</Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="અંદાજે જન્મ વર્ષ"
                >
                  <Form.Control
                    size="lg"
                    type="number"
                    value={p1by || ""}
                    onChange={(e) => {
                      setP1by(e.target.value);
                    }}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="અંદાજે અવસાન વર્ષ"
                >
                  <Form.Control
                    size="lg"
                    type="number"
                    value={p1dy || ""}
                    onChange={(e) => {
                      setP1dy(e.target.value);
                    }}
                  />
                </FloatingLabel>
              </Col>
              <Col xs="auto">
                <FloatingLabel controlId="floatingInput" label="Number">
                  <Form.Select
                    value={p1n || ""}
                    onChange={(e) => {
                      setP1n(e.target.value);
                    }}
                  >
                    <option value=""></option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>&zwnj;</Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="માતાપિતાનો ID નંબર"
                >
                  <Form.Control
                    size="lg"
                    type="number"
                    value={p1p || ""}
                    onChange={(e) => {
                      setP1p(e.target.value);
                    }}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <div className="d-grid gap-2">
                  {(p1p && p1p.length === 6 && (
                    <Button
                      onClick={async (e) => {
                        const result = await Swal.fire({
                          title: `ઓપન ફેમિલી: ${p1p}?`,
                          showDenyButton: true,
                          confirmButtonText: "હા / Yes",
                        });
                        if (!result.isDenied) {
                          navigate(`/Edit?id=${p1p}`);
                          setCurrentNode(p1p);
                        }
                      }}
                    >
                      માતાપિતા એડિટ
                    </Button>
                  )) || (
                    <Button
                      onClick={async (e) => {
                        const result = await Swal.fire({
                          title: `${id} ના માતાપિતા ઉમેરો?`,
                          showDenyButton: true,
                          confirmButtonText: "હા / Yes",
                        });
                        if (result.isConfirmed) {
                          const parentId = await updateNode({
                            p1l,
                            npv,
                          });
                          await updateNode({
                            id,
                            p1n,
                            p1f,
                            p1l,
                            p1by,
                            p2n,
                            p2f,
                            p2l,
                            p2by,
                            npv,
                            p1p: parentId,
                            p2p,
                          });
                          navigate(`/Edit?id=${parentId}`);
                          setCurrentNode(parentId);
                        }
                      }}
                    >
                      માતાપિતા એડ કરો
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>&zwnj;</Col>
            </Row>
            {p1plineage && (
              <Row>
                <Col>
                  <Form.Label>પિતૃ વંશ</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={p1plineage || ""}
                  />
                </Col>
              </Row>
            )}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>સ્ત્રી</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="નામ (અટક બાજુમાં આવસે)"
                >
                  <Form.Control
                    size="lg"
                    type="text"
                    value={p2f || ""}
                    onChange={(e) => {
                      console.log({ e });
                      setP2f(e.target.value);
                    }}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel controlId="floatingInput" label="સરનેમ અટક">
                  <Form.Control
                    size="lg"
                    type="text"
                    value={p2l || ""}
                    onChange={(e) => {
                      console.log({ e });
                      setP2l(e.target.value);
                    }}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>&zwnj;</Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="અંદાજે જન્મ વર્ષ"
                >
                  <Form.Control
                    size="lg"
                    type="number"
                    value={p2by || ""}
                    onChange={(e) => {
                      setP2by(e.target.value);
                    }}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="અંદાજે અવસાન વર્ષ"
                >
                  <Form.Control
                    size="lg"
                    type="number"
                    value={p2dy || ""}
                    onChange={(e) => {
                      setP2dy(e.target.value);
                    }}
                  />
                </FloatingLabel>
              </Col>
              <Col xs="auto">
                <FloatingLabel controlId="floatingInput" label="Number">
                  <Form.Select
                    value={p2n || ""}
                    onChange={(e) => {
                      setP2n(e.target.value);
                    }}
                  >
                    <option value=""></option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col>&zwnj;</Col>
            </Row>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="floatingInput"
                  label="માતાપિતાનો ID નંબર"
                >
                  <Form.Control
                    size="lg"
                    type="number"
                    value={p2p || ""}
                    onChange={(e) => {
                      setP2p(e.target.value);
                    }}
                  />
                </FloatingLabel>
              </Col>
              <Col>
                <div className="d-grid gap-2">
                  {(p2p && p2p.length === 6 && (
                    <Button
                      onClick={async (e) => {
                        const result = await Swal.fire({
                          title: `ઓપન ફેમિલી: ${p2p}?`,
                          showDenyButton: true,
                          confirmButtonText: "હા / Yes",
                        });
                        if (!result.isDenied) {
                          navigate(`/Edit?id=${p2p}`);
                          setCurrentNode(p2p);
                        }
                      }}
                    >
                      માતાપિતા એડિટ
                    </Button>
                  )) || (
                    <Button
                      onClick={async (e) => {
                        const result = await Swal.fire({
                          title: `${id} ના માતાપિતા ઉમેરો?`,
                          showDenyButton: true,
                          confirmButtonText: "હા / Yes",
                        });
                        if (result.isConfirmed) {
                          const parentId = await updateNode({
                            p2l,
                          });
                          await updateNode({
                            id,
                            p1n,
                            p1f,
                            p1l,
                            p1by,
                            p2n,
                            p2f,
                            p2l,
                            p2by,
                            npv,
                            p1p,
                            p2p: parentId,
                          });
                          navigate(`/Edit?id=${parentId}`);
                          setCurrentNode(parentId);
                        }
                      }}
                    >
                      માતાપિતા એડ કરો
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>&zwnj;</Col>
            </Row>
            {p2plineage && (
              <Row>
                <Col>
                  <Form.Label>પિતૃ વંશ</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={p2plineage || ""}
                  />
                </Col>
              </Row>
            )}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>મૂળ ગામ</Accordion.Header>
          <Accordion.Body>
            <Form.Control
              size="lg"
              type="text"
              value={npv || ""}
              onChange={(e) => {
                setNpv(e.target.value);
              }}
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>ફેમિલી ફોટો</Accordion.Header>
          <Accordion.Body>
            <img src={dp} />
            <Row>
              <Col>&zwnj;</Col>
            </Row>
            <Form.Label>
              મહત્વની નોંધ: આ ફેમિલી ફોટો સાર્વજનિક છે તેથી કૃપા કરીને તમારા
              કુટુંબનો યોગ્ય ફોટો અપલોડ કરો
            </Form.Label>
            <Row>
              <Col>&zwnj;</Col>
            </Row>
            <Form.Control
              accept="image/*"
              type="file"
              onChange={(e) => {
                const { files } = e.target;
                const filesArray = [...files];
                const compress = new Compress();
                compress
                  .compress(filesArray, {
                    size: 0.1, // the max size in MB, defaults to 2MB
                    // quality: 0.1, // the quality of the image, max is 1,
                    maxWidth: 800, // the max width of the output image, defaults to 1920px
                    maxHeight: 400, // the max height of the output image, defaults to 1920px
                    resize: true, // defaults to true, set false if you do not want to resize the image width and height
                    rotate: false, // See the rotation section below
                  })
                  .then((compressedFilesArray) => {
                    const { data, ext } = compressedFilesArray[0];
                    setDp(`data:${ext};base64,${data}`);
                  });
              }}
            />
          </Accordion.Body>
        </Accordion.Item>
        <Row>
          <Col>&zwnj;</Col>
        </Row>
        <Row>
          <Col>
            <div className="d-grid gap-2">
              <Button type="submit">ડેટા અપડેટ કરો</Button>
            </div>
          </Col>
          <Col>
            <div className="d-grid gap-2">
              <Button
                variant="secondary"
                onClick={(e) => {
                  navigate("/?id=" + currentNode);
                }}
              >
                ગ્રાફ પર પાછા
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>&zwnj;</Col>
        </Row>
        <Accordion.Item eventKey="3">
          <Accordion.Header>આ ફેમિલીના સંતાન</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col>
                {childFamilies &&
                  childFamilies.map((node, i) => getChildCard(i, node))}
              </Col>
            </Row>
            <Row>
              <Col>&zwnj;</Col>
            </Row>
            <Row>
              <Col>
                <div className="d-grid gap-2">
                  <Button
                    onClick={async (e) => {
                      const result = await Swal.fire({
                        title: `${id} ના પુત્ર ઉમેરો?`,
                        showDenyButton: true,
                        confirmButtonText: "હા / Yes",
                      });
                      if (!result.isDenied) {
                        navigate(`/Edit?id=&p1p=${id}&npv=${npv}&p1l=${p1l}`);
                        setCurrentNode("");
                      }
                    }}
                  >
                    પુત્ર ઉમેરો
                  </Button>
                </div>
              </Col>
              <Col>
                <div className="d-grid gap-2">
                  <Button
                    className="btn-info"
                    onClick={async (e) => {
                      const result = await Swal.fire({
                        title: `${id} ના પુત્રી ઉમેરો?`,
                        showDenyButton: true,
                        confirmButtonText: "હા / Yes",
                      });
                      if (!result.isDenied) {
                        navigate(`/Edit?id=&p2p=${id}&npv=${npv}&p2l=${p1l}`);
                        setCurrentNode("");
                      }
                    }}
                  >
                    પુત્રી ઉમેરો
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>&zwnj;</Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Form>
  );

  function getValueOrBlank(value) {
    return value ? value : "";
  }

  function getChildCard(i, node) {
    let title = "";
    let body = "";
    if (node.p1p === currentNode) {
      title = getValueOrBlank(node.p1f) + " " + getValueOrBlank(node.p1l);
      body = getValueOrBlank(node.p2f) + " " + getValueOrBlank(node.p2l);
    } else {
      title = getValueOrBlank(node.p2f) + " " + getValueOrBlank(node.p2l);
      body = getValueOrBlank(node.p1f) + " " + getValueOrBlank(node.p1l);
    }
    return (
      <Card
        key={i}
        onClick={async (e) => {
          const result = await Swal.fire({
            title: `Open family ${node.id}?`,
            showDenyButton: true,
            confirmButtonText: "હા / Yes",
          });
          if (!result.isDenied) {
            navigate(`/Edit?id=${node.id}`);
            setCurrentNode(node.id);
          }
        }}
      >
        <Card.Body>
          {title}
          <br />
          {body}
        </Card.Body>
      </Card>
    );
  }
};

export default EditFamily;
