import "./index.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  searchNodes,
  getNodeTextForSearch,
  MySpinner,
  fireOpenFamilySwal,
} from "../helpers";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";

const { useState } = React;

const SearchFamily = () => {
  const navigate = useNavigate();

  const [f, setF] = useState("");
  const [l, setL] = useState("");

  const [npv, setNpv] = useState("");

  const [showSpinner, setShowSpinner] = useState(false);

  const [searchResultFamilies, setSearchResultFamilies] = useState([]);

  const handleSearch = async (e) => {
    if (e) e.preventDefault();

    if (!f && !l && !npv) {
      return Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Atleast one name is required",
        showConfirmButton: true,
      });
    }
    setShowSpinner(true);
    setSearchResultFamilies(await searchNodes({ f, l, npv }));
    setShowSpinner(false);
  };

  function getChildCard(i, node) {
    const lines = getNodeTextForSearch(node).split("\n");
    return (
      <Card
        key={i}
        onClick={async (e) => {
          const result = await fireOpenFamilySwal(node);
          if (result.isDenied) {
            navigate(`/Edit?id=${node.id}`);
          } else if (result.isConfirmed) {
            navigate(`/?id=${node.id}`);
          } else if (result.isDismissed && result.dismiss == "cancel") {
            navigate(`/Search`);
          }
        }}
      >
        <Card.Body
          className={
            [
              "bg-primary",
              "bg-secondary",
              "bg-success",
              "bg-danger",
              "bg-info",
              "bg-dark",
            ].sort(() => 0.5 - Math.random())[0]
          }
        >
          {lines.map((line, i) => {
            return <Card.Text key={i}>{line}</Card.Text>;
          })}
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Form onSubmit={handleSearch}>
        <Row>
          <Col>&zwnj;</Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>પ્રથમ નામ (અધૂરું નાખશો તોય ચાલસે)</Form.Label>
            <Form.Control
              value={f || ""}
              onChange={(e) => {
                setF(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>સરનેમ/અટક (અધૂરું નાખશો તોય ચાલસે)</Form.Label>
            <Form.Control
              value={l || ""}
              onChange={(e) => {
                setL(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>&zwnj;</Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>ગામ (અધૂરું નાખશો તોય ચાલસે)</Form.Label>

            <Form.Control
              value={npv || ""}
              onChange={(e) => {
                setNpv(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>&zwnj;</Col>
        </Row>
        <Row>
          <Col>
            <div className="d-grid gap-2">
              <Button type="submit">સર્ચ</Button>
            </div>
          </Col>
          <Col>
            <div className="d-grid gap-2">
              <Button
                variant="secondary"
                onClick={(e) => {
                  navigate("/");
                }}
              >
                ગ્રાફ પર પાછા
              </Button>
            </div>
          </Col>
          <Col>
            <div className="d-grid gap-2">
              <Button
                className="btn-success"
                onClick={async (e) => {
                  const result = await Swal.fire({
                    title: "નવી ફેમિલી ઉમેરો?",
                    confirmButtonText: "હા / Yes",
                    showDenyButton: true,
                  });
                  if (!result.isDenied) {
                    navigate(`/Edit?id=`);
                  }
                }}
              >
                નવી ફેમિલી ઉમેરો
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>&zwnj;</Col>
        </Row>
      </Form>
      <hr />
      <Row>
        <Col>Search Results: {searchResultFamilies.length}</Col>
      </Row>
      {showSpinner && MySpinner}
      <Row>
        <Col>
          {searchResultFamilies &&
            searchResultFamilies.map((node, i) => (
              <>&zwnj;{getChildCard(i, node)}</>
            ))}
        </Col>
      </Row>
    </>
  );
};

export default SearchFamily;
