import "./index.css";
import React from "react";
import ReactForceGraph2d from "react-force-graph-2d";
import { forceCollide } from "d3-force";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  getInitialNodeFromStorage,
  setInitialNodeInStorage,
  getNodeText,
  MySpinner,
  getFamilyNodes,
  fireOpenFamilySwal,
} from "../helpers";

const { useState, useEffect, useRef, useCallback } = React;

const boyColor = "rgb(0, 150, 255)";
const girlColor = "rgb(255, 0, 255)";

console.log(performance.now() + " Loading App.js");

// Get Canvas Object Function
const getCanvasObject = (node, ctx, globalScale) => {
  const fontSize = 10;
  const padding = fontSize;
  const lines = getNodeText(node).split("\n");
  const textWidth = Math.max(
    ...lines.map((line) => ctx.measureText(line).width)
  );

  const bckgDimensions = [textWidth, fontSize * lines.length].map(
    (n) => n + padding
  ); // some padding

  ctx.strokeStyle = node.color;
  ctx.lineWidth = 1.5;
  ctx.fillStyle = node.color + "cc";
  ctx.beginPath();
  ctx.roundRect(
    node.x - bckgDimensions[0] / 2,
    node.y - bckgDimensions[1] / 2,
    ...bckgDimensions,
    [5]
  );
  ctx.fill();
  ctx.stroke();

  if (node.dp) {
    const image = new Image();
    image.src = node.dp;
    // image.style.borderRadius = "50%";
    ctx.drawImage(
      image,
      node.x - bckgDimensions[0] / 2 + 1,
      node.y + bckgDimensions[1] / 2 - 11,
      10,
      10
    );
  }

  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.fillStyle = "black";
  ctx.font = `bolder ${fontSize}px serif`;
  ctx.fillText(
    lines[0],
    node.x,
    node.y - bckgDimensions[1] / 2 + padding + 0 * fontSize
  );
  ctx.fillText(
    lines[1],
    node.x,
    node.y - bckgDimensions[1] / 2 + padding + 1 * fontSize
  );

  ctx.font = `${fontSize}px serif`;
  for (let i = 2; i < lines.length; i++) {
    ctx.fillText(
      lines[i],
      node.x,
      node.y - bckgDimensions[1] / 2 + padding + i * fontSize
    );
  }

  node.__bckgDimensions = bckgDimensions; // to re-use in nodePointerAreaPaint
};

// Should Render Graph Function
const shouldRenderGraph = (graphData) => {
  const result = graphData && graphData.nodes && graphData.nodes.length !== 0;
  console.log("shouldRenderGraph = " + result);
  return result;
};

// Initialize graphData as blank graph
let newGraphData = { nodes: [], links: [] };

const newGraphDataContainsNode = (id) => {
  return newGraphData.nodes.some((node) => node.id === id);
};

const newGraphDataContainsLink = (s, t) => {
  return newGraphData.links.some((link) => link.s === s && link.t === t);
};

const newGraphDataAddNode = (newNode, linkedNode) => {
  if (newGraphDataContainsNode(newNode.id)) {
    console.log(`Node already present: ${newNode.id}`);
  } else {
    if (linkedNode) {
      // newNode.x = linkedNode.x;
      // newNode.y = linkedNode.y + 100;
    }
    newGraphData.nodes.push(newNode);
    console.log(`Added node: ${newNode.id}`);
  }
};

const newGraphDataAddLink = (s, t, color) => {
  if (newGraphDataContainsLink(s, t)) {
    console.log(`Link already present: ${s}->${t}`);
  } else {
    newGraphData.links.push({ s, t, color });
    console.log(`Link added: ${s}->${t}`);
  }
};

const loadData = async (mainId) => {
  newGraphData = { nodes: [], links: [] };
  console.log(
    "Loading data for " + mainId + JSON.stringify(newGraphData, null, 2)
  );

  const familyNode = await getFamilyNodes(mainId);
  console.log(familyNode);
  for (let i = 0; i < familyNode.nodes.length; i++) {
    const node = familyNode.nodes[i];
    console.log({ node });
    newGraphDataAddNode(node);
  }

  for (let i = 0; i < newGraphData.nodes.length; i++) {
    const node = newGraphData.nodes[i];
    if (node.p1p && newGraphDataContainsNode(node.p1p)) {
      newGraphDataAddLink(node.p1p, node.id, boyColor);
    }

    if (node.p2p && newGraphDataContainsNode(node.p2p)) {
      newGraphDataAddLink(node.p2p, node.id, girlColor);
    }
  }
};

const App = () => {
  console.log("Start of React App component");
  const navigate = useNavigate();
  const fgRef = useRef();
  const [searchParams] = useSearchParams();

  // Get current node from search param
  const [currentNode, setCurrentNode] = useState(
    searchParams.get("id") || searchParams.get("node")
  );
  console.log(`currentNode = ${currentNode}`);
  const [graphData, setGraphData] = useState({ nodes: [], links: [] });

  const getUserInput = useCallback(async () => {
    const inputValue = getInitialNodeFromStorage();
    const { value } = await Swal.fire({
      title: "તમારું ID એન્ટર કરો",
      footer:
        'તમારું ID જાણવા માટે નીચેના નંબર ઉપર વોટ્સ એપ મેસેજ મોકલાવો. <a href="https://wa.me/919925075434">9925075434</a>, <a href="https://wa.me/919662396567">9662396567</a>',
      text: "આ પ્રોજેક્ટ વંશાવલી કે આંબો નથી પરંતુ આજના વ્યસ્ત જીવનમાં આપણી નવી પેઢીને સગા-સંબંધીઓની જાણકારી આપી પૂર્વજોના આશીર્વાદ લેવાનો પ્રયાસ છે.",
      input: "text",
      inputValue,
      inputValidator: (value) => {
        if (!value || !(value.length === 6)) {
          return "Invalid ID";
        }
      },
    });
    if (value && value.length === 6) {
      setInitialNodeInStorage(value);
      setCurrentNode(value);
    }
  }, []);

  // Load data when current node changes
  useEffect(() => {
    const currentNodeChangedEffect = async () => {
      if (currentNode && currentNode.length === 6) {
        navigate("/?id=" + currentNode);
        await loadData(currentNode);
        setGraphData({ ...newGraphData });
      } else getUserInput();
    };
    setGraphData({ nodes: [], links: [] });
    currentNodeChangedEffect();
  }, [currentNode, navigate, getUserInput]);

  // Update the collition force when graphData changes
  useEffect(() => {
    console.log(
      "useEffect triggered for graphData. Number of nodes in graphData = " +
        graphData.nodes.length
    );
    if (fgRef.current) {
      fgRef.current.d3Force("collision", forceCollide(30));
      // fgRef.current.d3Force("charge", -2000);
    }
  }, [graphData]);

  // Handle Click Function
  const handleClick = useCallback(
    async (node) => {
      const result = await fireOpenFamilySwal(node);
      if (result.isDenied) {
        navigate(`/Edit?id=${node.id}`);
      } else if (result.isConfirmed) {
        setCurrentNode(node.id);
      } else if (result.isDismissed && result.dismiss == "cancel") {
        navigate(`/Search`);
      }
    },
    [navigate]
  );

  // Graph JSX
  const MyGraph = (
    <ReactForceGraph2d
      ref={fgRef}
      graphData={graphData}
      // DAG params
      dagMode="lr"
      dagLevelDistance={200}
      // Node params
      nodeAutoColorBy="id"
      nodeRelSize={20}
      // nodeLabel={getLabel}
      nodeCanvasObject={getCanvasObject}
      enableNodeDrag={false}
      onNodeClick={handleClick}
      // Link params
      linkSource="s"
      linkTarget="t"
      // linkAutoColorBy="s" // color attribute is already added
      linkDirectionalArrowColor={(link) => link.source.color}
      linkWidth={1}
      linkDirectionalParticles={2}
      linkDirectionalParticleWidth={3}
      linkDirectionalParticleSpeed={0.002}
      linkDirectionalArrowLength={15}
      // linkDirectionalArrowRelPos={0.85}
      linkOpacity={0.2}
      // linkCurvature={1}
      // onLinkClick={handleLinkClick}
      onEngineStop={() => {
        // fgRef.current?.zoomToFit(500);
      }}
      // cooldownTicks={500}
      warmupTicks={500}
      cooldownTime={3000}
      d3VelocityDecay={0.3}
    />
  );

  console.log("Just before return JSX");
  return <>{(shouldRenderGraph(graphData) && MyGraph) || MySpinner}</>;
};

export default App;

// const handleLinkClick = useCallback(
//   async (link, event) => {
//     delete retrievedNodes.current[link.source.id];
//     updateGraphData();
//   },
//   [updateGraphData]
// );

// const handleNodeRightClick = useCallback(
//   async (node, event) => {
//     delete retrievedNodes.current[node.id];
//     updateGraphData();
//   },
//   [updateGraphData]
// );

// useEffect(() => {
//   console.log("Running initialNode " + initialNode);
//   if (initialNode.length === 6) {
//     fetch(apiDomain + "/node/" + initialNode + "?initialNode=true").then(
//       (response) => {
//         response.json().then((data) => {
//           data.c = "+";
//           data.g = 0; // generation
//           data.r = rel.self; // relationship
//           retrievedNodes.current[data.id] = data;
//           updateGraphData();
//         });
//       }
//     );
//   }
// }, [initialNode, updateGraphData]);

// const rel = {
//   pitamah: "પિતામહ",
//   dadamah: "દાદામહ",

//   matamah: "માતામહ",
//   nanamah: "નાનામહ",

//   pardadadadi: "પરદાદાજી/પરદાદીમા",
//   parnananani: "પરનાનાજી/પરનાનીમા",

//   dadadadi: "દાદાજી/દાદીમા",
//   nananani: "નાનાજી/નાનીમા",

//   parents: "માતા/પિતા",

//   masimasa: "માસીબા/માસા સાહેબ",
//   mamamami: "મામાજી/મામીજી",
//   motabapuji: "મોટાબાપુજી/મોટીમા",
//   kakakaki: "કાકાજી/કાકીમા",
//   faifua: "ફઈબા/ફૂઆ સાહેબ",

//   self: "પોતે",

//   bhai: "ભાઈ/ભાભી",
//   bahen: "બહેન/બનેવી સાહેબ",

//   putra: "પુત્ર/પુત્રવધુ",
//   putri: "પુત્રી/જમાઈ સાહેબ",

//   bhatrija: "ભત્રીજા",
//   bhatriji: "ભત્રીજી",
//   bhaneja: "ભાણુભા",
//   bhaneji: "ભાણીબા",

//   potra: "પૌત્ર",
//   potri: "પૌત્રી",
//   dohitra: "દોહિત્રા",
//   dohitri: "દોહિત્રી",
// };
